.contentDetail {
  border: 1px solid #f0f0f0;
  max-width: 40%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  ul {
    margin: 0;
    list-style: none;
    width: 100%;
  }
  .boxLeft li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.wrapContentItem {
  margin-left: 50px;
  .itemTitle {
    font-family: NanumGothic;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
  }
}
.boxDogItem {
  display: flex;
  width: 100%;
  .itemLeft {
    width: 50%;
    text-align: right;
  }
  .itemRight {
    width: 50%;
  }
}
:global {
  .ant-table-content {
    border: 1px solid #dee2e6;
  }
  .btnBack {
    margin-left: 20px;
    border: none;
    background: none;
    display: flex;
    height: 100%;
  }
  .iconBack {
    font-size: 18px;
    height: 100%;
    padding-right: 4px;
  }
  .textBack {
    font-weight: 800;
    font-size: 14px;
    line-height: 60px;
  }
}

:global {
  .wrapContent::-webkit-scrollbar {
    display: none;
  }
}
