.wrapSearch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
}
.searchInput {
  max-width: 300px;
}

:global {
  .formSearch {
    display: flex;
  }

  .btnSearch span {
    letter-spacing: -2px;
  }
}
