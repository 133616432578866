.wrapLogin {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  max-width: 460px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  max-height: 260px;
}
.loginForm {
  min-width: 400px;
}

.remember{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.loginBtn {
  width: 100px;
  height: 35px;
}
.iconForm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.inputForm {
  :global {
    svg {
      margin: 0 !important;
    }
    .ant-form-item-explain-error {
      float: left;
    }
}
}

:global {
  .ant-form-item-explain-error {
    float: left;
  }

}

.logo {
  margin-bottom: 30px;
}

:global {
  .ant-form-item .ant-form-item-label >label::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
    right: 0;
    position: absolute;
  }
}