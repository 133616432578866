.wrapRegister {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputForm {
  :global {
    svg {
      margin: 0 !important;
    }
    .ant-form-item-explain-error {
      float: left;
    }
}
}

:global {
  .ant-form-item-explain-error {
    float: left;
  }

}