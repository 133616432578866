body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  margin: 0 !important;
}

 {
  .ant-form-item-explain-error {
    float: left;
  }
}

header h4 {
  font-size: 20px;
  font-weight: 800;
}

.textLink {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-main {
  display: flex;
  flex-direction: column;
}

.wrapCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-border-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-border {
  z-index: 9999;
  color: #fff;
}
.footer {
  margin-top: auto;
}
footer .main-footer {
  padding: 20px 0;
  background: #252525;
}
footer ul {
  padding-left: 0;
  list-style: none;
}

/* Copy Right Footer */
.footer-copyright {
  background: #222;
  padding: 5px 0;
}
.footer-copyright .logo {
  display: inherit;
}
.footer-copyright nav {
  float: right;
  margin-top: 5px;
}
.footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-copyright nav ul li {
  border-left: 1px solid #505050;
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}
.footer-copyright nav ul li a {
  color: #969696;
}
.footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}
.footer-copyright p {
  color: #969696;
  margin: 2px 0 0;
}

/* Footer Top */
.footer-top {
  background: #252525;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #222;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
  background: transparent;
}
footer.transparent .footer-copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

/* Footer light */
footer.light .footer-top {
  background: #f9f9f9;
}
footer.light .main-footer {
  background: #f9f9f9;
}
footer.light .footer-copyright {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
}

/* Footer 4 */
.footer- .logo {
  display: inline-block;
}

/*==================== 
  Widgets 
====================== */
.widget {
  padding: 20px;
  margin-bottom: 40px;
}
.widget.widget-last {
  margin-bottom: 0px;
}
.widget.no-box {
  padding: 0;
  background-color: transparent;
  margin-bottom: 40px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
}
.widget.subscribe p {
  margin-bottom: 18px;
}
.widget li a {
  color: #ff8d1e;
}
.widget li a:hover {
  color: #4b92dc;
}
.widget-title {
  margin-bottom: 20px;
}
.widget-title span {
  background: #839fad none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 25px;
  position: relative;
  width: 20%;
}
.widget-title span::after {
  background: inherit;
  content: '';
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}
.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.widget .badge {
  float: right;
  background: #7f7f7f;
}

.typo-light h1,
.typo-light h2,
.typo-light h3,
.typo-light h4,
.typo-light h5,
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small {
  color: #fff;
}

 {
  .ant-form-item-explain-error {
    float: left;
  }
  .ant-layout-sider {
    background-color: #4b92dc;
    position: relative !important;
  }
}
.ant-layout-sider {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
}
.ant-layout {
  flex-direction: unset;
}

.styles_wrapLogin__38QCZ {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  max-width: 460px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  max-height: 260px;
}

.styles_loginForm__3Lf0G {
  min-width: 400px;
}

.styles_remember__3sqV6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.styles_loginBtn__1KbPZ {
  width: 100px;
  height: 35px;
}

.styles_iconForm__rR_un {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.styles_inputForm__3HlCo svg {
  margin: 0 !important;
}
.styles_inputForm__3HlCo .ant-form-item-explain-error {
  float: left;
}

.ant-form-item-explain-error {
  float: left;
}

.styles_logo__2thzl {
  margin-bottom: 30px;
}

.ant-form-item .ant-form-item-label > label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: 0;
  position: absolute;
}
.styles_wrapLogin__2fAmy {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 30px;
  max-width: 460px;
  max-height: 430px;
}

.styles_loginForm__2dKUq {
  min-width: 400px;
}

.styles_remember__3YW1L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.styles_loginBtn__M2HWc {
  width: 100px;
  height: 35px;
}

.styles_iconForm__3tgKn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.styles_logo__3f8dK {
  width: 150px !important;
  margin-bottom: 30px;
}

.ant-form-item .ant-form-item-label > label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: 0;
  position: absolute;
}
.styles_wrapLogin__3OBRJ {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 30px;
  max-width: 460px;
  max-height: 430px;
}

.styles_loginForm__2MM-m {
  min-width: 400px;
}

.styles_remember__1-xxc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.styles_loginBtn__2qtoF {
  width: 100px;
  height: 35px;
}

.styles_iconForm__3HWSP {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.styles_logo__Xolq5 {
  width: 150px !important;
  margin-bottom: 30px;
}

.ant-form-item .ant-form-item-label > label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: 0;
  position: absolute;
}
.style_wrapRegister__1EohD {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_inputForm__3eIJb svg {
  margin: 0 !important;
}
.style_inputForm__3eIJb .ant-form-item-explain-error {
  float: left;
}

.ant-form-item-explain-error {
  float: left;
}
.styles_wrapSearch__3WDWN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
}

.styles_searchInput__2vOFg {
  max-width: 300px;
}

.formSearch {
  display: flex;
}
.btnSearch span {
  letter-spacing: -2px;
}
.style_wrapCharContent__2PX-y {
  max-width: 70%;
}
.style_contentDetail__1dpEG {
  border: 1px solid #f0f0f0;
  max-width: 40%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.style_contentDetail__1dpEG ul {
  margin: 0;
  list-style: none;
  width: 100%;
}
.style_contentDetail__1dpEG .style_boxLeft__3GdXQ li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.style_wrapContentItem__2XPxS {
  margin-left: 50px;
}
.style_wrapContentItem__2XPxS .style_itemTitle__1sZr5 {
  font-family: NanumGothic;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
}

.style_boxDogItem__1qd3D {
  display: flex;
  width: 100%;
}
.style_boxDogItem__1qd3D .style_itemLeft__2FQ-6 {
  width: 50%;
  text-align: right;
}
.style_boxDogItem__1qd3D .style_itemRight__3xadG {
  width: 50%;
}

.ant-table-content {
  border: 1px solid #dee2e6;
}
.btnBack {
  margin-left: 20px;
  border: none;
  background: none;
  display: flex;
  height: 100%;
}
.iconBack {
  font-size: 18px;
  height: 100%;
  padding-right: 4px;
}
.textBack {
  font-weight: 800;
  font-size: 14px;
  line-height: 60px;
}

.wrapContent::-webkit-scrollbar {
  display: none;
}
.styles_wrapPanigation__exxUV {
  display: flex;
  justify-content: end;
  padding-top: 30px;
}
.style_contentDetail__RKdTJ {
  font-family: NanumGothic;
  border: 1px solid #f0f0f0;
  max-width: 400px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.style_contentDetail__RKdTJ ul {
  margin: 0;
  list-style: none;
  width: 100%;
  padding-left: 0;
}
.style_contentDetail__RKdTJ .style_boxLeft__35TNi li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: NanumGothic;
}

.style_boxDogItem__r8LxJ {
  display: flex;
  width: 100%;
}
.style_boxDogItem__r8LxJ .style_itemLeft__1CxDP {
  width: 50%;
  text-align: right;
}
.style_boxDogItem__r8LxJ .style_itemRight__2sg9x {
  width: 50%;
}

.ant-table-content {
  font-family: NanumGothic;
  border: 1px solid #dee2e6;
}
.footerDetail {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
  background: #D9D9D9;
  height: 60px;
  align-items: center;
  line-height: 60px;
}
.btnBack {
  margin-left: 20px;
  border: none;
  background: none;
  display: flex;
  height: 100%;
}
.iconBack {
  font-size: 18px;
  height: 100%;
  padding-right: 4px;
}
.textBack {
  font-family: NanumGothic;
  font-weight: 800;
  font-size: 14px;
  line-height: 60px;
}

.style_title__8pqJa {
  font-family: NanumGothic;
}
.btn.selected {
    background: black;
    font-weight: bold;
}

.text-error {
  color: red;
  font-size: 14px;
}
